// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import PhoneVerification from "../../blocks/PhoneVerification/src/PhoneVerification";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import SearchHistory from "../../blocks/SearchHistory/src/SearchHistory";
import VideoCall5 from "../../blocks/VideoCall5/src/VideoCall5";
import AppointmentManagement from "../../blocks/AppointmentManagement/src/AppointmentManagement";
import Download from "../../blocks/Download/src/Download";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import Payments from "../../blocks/Payments/src/Payments";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CustomForm2 from "../../blocks/CustomForm2/src/CustomForm2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import Scheduling from "../../blocks/Scheduling/src/Scheduling";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Share from "../../blocks/Share/src/Share";
import AudioCall from "../../blocks/AudioCall/src/AudioCall";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Sms2 from "../../blocks/Sms2/src/Sms2";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Reviews from "../../blocks/Reviews/src/Reviews";
import Location4 from "../../blocks/Location4/src/Location4";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Calendar from "../../blocks/Calendar/src/Calendar";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";



const routeMap = {
PhoneVerification:{
 component:PhoneVerification,
path:"/PhoneVerification"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
SearchHistory:{
 component:SearchHistory,
path:"/SearchHistory"},
VideoCall5:{
 component:VideoCall5,
path:"/VideoCall5"},
AppointmentManagement:{
 component:AppointmentManagement,
path:"/AppointmentManagement"},
Download:{
 component:Download,
path:"/Download"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
Payments:{
 component:Payments,
path:"/Payments"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
CustomForm2:{
 component:CustomForm2,
path:"/CustomForm2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
DiscountsOffers:{
 component:DiscountsOffers,
path:"/DiscountsOffers"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Share:{
 component:Share,
path:"/Share"},
AudioCall:{
 component:AudioCall,
path:"/AudioCall"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Sms2:{
 component:Sms2,
path:"/Sms2"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
Location4:{
 component:Location4,
path:"/Location4"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Calendar:{
 component:Calendar,
path:"/Calendar"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;